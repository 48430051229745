module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-NGVLLXC","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"AccroPress"}}},
    },{
      plugin: require('../node_modules/gatsby-plugin-sentry/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://31544eb084ae44539700cd4d7e0ca55d@sentry.accropress.com/5","environment":"production","enabled":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Activate Learning Careers","short_name":"Activate Learning Careers","start_url":"/","background_color":"#fff","theme_color":"#000","display":"standalone","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":null},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
